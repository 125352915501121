:root {
  --slingshot-white-95: hsla(0, 0%, 95%, 1);
  --slingshot-aqua-30: hsla(224, 49%, 21%, 1);
  --slingshot-aqua-20: hsla(224, 95%, 10%, 1);
  --slingshot-aqua: hsla(170, 49%, 49%, 1);
  --slingshot-white: rgb(255, 255, 255);
  --background: hsla(235, 10%, 10%, 1);
  --accent: hsla(235, 10%, 25%, 1);
  --base-red: hsla(360, 72%, 57%, 1);
  --base-red-77: hsla(360, 72%, 77%, 1);
  --base-yellow: hsla(38, 98%, 54%, 1);
  --base-yellow-74: hsla(38, 98%, 74%, 1);
  --base-green: hsla(166, 97%, 39%, 1);
  --base-green-59: hsla(166, 97%, 59%, 1);
}
